<template>
  <div class="goodslist">
    <Header url="GoodsList"></Header>
    <div class="catebg">
      <img src="../assets/img/allSpc.jpg" />
      <div class="bgtitle">二级分类名称</div>
    </div>
    <div class="glist">

      <div  class="item">
         <li class="goodsinfo">
             <img src="../assets/img/11.jpg" alt="" @click="showimg('11')">
         </li>
        <div class="imgtitle line1">西红柿炒鸡蛋  <span @click="gocart()">+</span></div>
      </div>
      <div  class="item">
        <li class="goodsinfo">
          <img src="../assets/img/22.jpg" alt="" @click="showimg('22')">
        </li>
        <div class="imgtitle line1">西红柿炒鸡蛋  <span @click="gocart()">+</span></div>
      </div>
      <div  class="item">
        <li class="goodsinfo">
          <img src="../assets/img/33.jpg" alt="" @click="showimg('33')">
        </li>
        <div class="imgtitle line1">西红柿炒鸡蛋  <span @click="gocart()">+</span></div>
      </div>
      <div  class="item"  v-for="(item,index) in goodslist" :key="index">
        <li class="goodsinfo">
          <img src="../assets/img/1.png" alt="" @click="showimg('1')">
        </li>
        <div class="imgtitle line1">西红柿炒鸡蛋  <span @click="gocart()">+</span></div>
      </div>
    </div>
    <Img  v-if="img.flag"  :src="img.src" @closeImg="closeImgFun"/>
    <Msg  v-if="msg.flag" :msg="msg"/>
    <Footer />
  </div>
</template>
<style scoped>
.goodslist{background: #f5f5f5}
.goodslist .catebg{position: relative}
.goodslist .bgtitle{position: absolute;    left: 50%;color: #fff;font-size: 18px;  top: 50%;  transform: translate(-50%, -50%);}
.goodslist  .glist{width: 1260px;overflow: hidden;margin: auto;}
.goodslist  .glist .item{position: relative;width:280px;height:335px;float: left;margin: 16px;
  display: inline-block;
  border: 1px solid #dfdfe3;
}
.goodslist  .glist .goodsinfo{width:270px;height:280px;
  cursor: pointer;
  display: table-cell;
  padding: 10px;
  vertical-align: middle;
  text-align: center;
  background-color: #fff;}
.goodslist  .glist .item img{max-width: 240px;max-height: 250px;width: auto;height: auto}
.goodslist  .glist .item .imgtitle{position: relative;
  padding: 10px;
  background: #fff;
  border-top: 1px solid #dfdfe3;}
.goodslist  .glist .item .imgtitle span{cursor:pointer;text-align:center;position: absolute;right: 5px;top:6px;color: #1ebcf0;border: 1px solid #1ebcf0;border-radius: 50%;    width: 20px;
  height: 20px;
  line-height: 20px;}
</style>
<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
import Msg from "@/components/msg";
import Img from "@/components/img";
export default {
  name: 'GoodsList',
  components:{Header,Footer,Msg,Img},
  data() {
    return {
      id:0,
      goodslist:[1,2,3,4,5,6,1,2,3,4,5,6,1,2,3,4,5,6,1,2,3,4,5,6],
      msg:{
          flag:false,
          msg:'',
          icon:false
      },
      img:{
        flag:false,
        src:''
      }

    }
  },
  watch: {
    '$route' (to) {
      this.id = to.query.cid;
    }
  } ,
  mounted(){ this.id = this.$route.query.cid;},
  methods: {
    gocart(){
        let that = this;
        this.msg.flag = true;
        this.msg.msg = '加入成功';
        setTimeout(function(){
           that.msg.flag = false;
        },600)
    },
    showimg(src){
        // src = 'http://pic.ntimg.cn/file/20210331/18549423_125448972031_2.jpg'
        this.img.flag = true;
        this.img.src = src;
    },
    closeImgFun(e){
      console.log(e)
      this.img.flag = false;
      this.img.src = '';
    }
  }
}
</script>
