<template>
  <div class="imgbox">
       <img class="closeimg" @click="closeimg()" src="../assets/img/close.png" alt="" />
       <div class="img" >
           <img src="../assets/img/1.png" alt="" />
       </div>
  </div>
 
</template>
<script>
export default {
  name: 'Img',
  props: {
    src: {
      type:String,
    }
  },
  methods:{
    closeimg(){
       this.$emit('closeImg',true);
    }
  }
}
</script>

<style scoped>
.imgbox{    position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 70vw;
  margin: auto;
  height: 80vh;
  z-index: 111;
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 15px #000;
  border-radius: 10px;
}
.imgbox .closeimg{width: 30px;height: 30px;position: absolute;margin: 10px;right:0;top:0;cursor: pointer;z-index: 112}
.imgbox .img{vertical-align: middle;display:table-cell;text-align: center;  width: 70vw;position: relative;
  margin: auto;
  height: 80vh;}
.imgbox .img img{max-width:60vw;max-height:70vh;width: auto;height: auto;}
</style>